module.exports = {
  // The repo name for your Prismic repository (found in the url of your repo: https://prydocs.prismic.io/)
  prismicRepo: 'prydocs',

  // Release ID to build (requires access)
  releaseID: undefined,

  // access token to access the release
  accessToken: '',

  // Language configuration in your Prismic repository: Settings / Translations & locals
  defaultLanguage: 'en-us',
  langs: ['en-us'],
};

import { Elements, RichText } from 'prismic-reactjs';
import * as React from 'react';
import { graphql, Link } from 'gatsby';
import useLocalStorage from '@utils/hooks/useLocalStorage';
import linkResolver from '@utils/linkResolver';
import { PrismicBannerFragmentFragment } from '@utils/typings/prismic-types';
import CloseIcon from '@icons/close-2.svg';
import * as styles from './styles.module.scss';

interface Props {
  banner: PrismicBannerFragmentFragment;
}
export default function Banner({ banner }: Props) {
  // We key by content so if a new banner is put up, we show it.
  const [show, setShow] = useLocalStorage(
    JSON.stringify(banner.data.text),
    true,
  );
  if (!show || !banner.data.show) {
    return null;
  }
  return (
    <div className={styles.banner}>
      <div className={styles.content}>
        <RichText
          render={banner.data.text.richText}
          htmlSerializer={htmlSerializer}
        />
      </div>
      <button className={styles.close} onClick={() => setShow(false)}>
        <CloseIcon />
      </button>
    </div>
  );
}

function htmlSerializer<T>(
  type: Elements,
  element: any,
  content: string,
  children: T[],
  key: string,
) {
  switch (type) {
    case Elements.paragraph: {
      // Paragraph
      return (
        <p key={key} className={styles.paragraph}>
          {children}
        </p>
      );
    }

    case Elements.hyperlink: {
      // Hyperlinks
      if (element.data.link_type === 'Document') {
        return (
          <Link
            key={key}
            to={linkResolver(element.data)}
            className={styles.hyperlink}
          >
            {content}
          </Link>
        );
      }

      return (
        <a
          key={key}
          id={element.data.id}
          href={element.data.url}
          className={styles.hyperlink}
        >
          {content}
        </a>
      );
    }

    default:
      // Always include a default that returns null
      return null;
  }
}

export const query = graphql`
  fragment PrismicBannerFragment on PrismicBanner {
    type
    data {
      show
      text {
        richText
      }
    }
  }
`;

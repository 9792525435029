const { defaultLanguage } = require('../../prismic-configuration');

/* The Link Resolver
* This function will be used to generate links to Prismic documents
As your project grows, you should update this function according to your routes */

const linkResolver = (doc) => {
  const properties = doc._meta || doc;

  if (properties.type === 'homepage') {
    return properties.lang === defaultLanguage ? `/` : `/${properties.lang}`;
  }

  if (properties.type === 'marketing_page') {
    return properties.lang === defaultLanguage
      ? `/${properties.uid}`
      : `/${properties.lang}/${properties.uid}`;
  }

  if (properties.type === 'doc_page') {
    return properties.lang === defaultLanguage
      ? `/docs/${properties.uid}`
      : `/docs/${properties.lang}/${properties.uid}`;
  }

  if (properties.type === 'academy_page') {
    return properties.lang === defaultLanguage
      ? `/academy/${properties.uid}`
      : `/academy/${properties.lang}/${properties.uid}`;
  }

  if (properties.type === 'blog_feed') {
    return properties.lang === defaultLanguage
      ? `/blog`
      : `/blog/${properties.lang}`;
  }

  if (properties.type === 'blog_page') {
    return properties.lang === defaultLanguage
      ? `/blog/${properties.uid}`
      : `/blog/${properties.lang}/${properties.uid}`;
  }

  if (properties.type === 'pricing_page') {
    return properties.lang === defaultLanguage
      ? `/${properties.uid}`
      : `/${properties.lang}/${properties.uid}`;
  }

  if (properties.type === 'partner_page') {
    return properties.lang === defaultLanguage
      ? `/partners/${properties.uid}`
      : `/partners/${properties.lang}/${properties.uid}`;
  }

  return '/';
};

module.exports = linkResolver;
